import type React from 'react'
import { useTranslation } from 'react-i18next'

export const errorResponseCodes = [
  'unsupported_image_format',
  'add other codes here...',
] as const

type ErrorResponseCodes = (typeof errorResponseCodes)[number]
type ErrorMessages = {
  [key in ErrorResponseCodes]?: string | React.ReactNode
}

// Error型の継承が必要かもしれない
// CustomError型としてちゃんと定義したほうが良いかもしれない
export function hasErrorResponseCode(
  obj: unknown,
): obj is { error_response_code: ErrorResponseCodes } {
  return typeof obj === 'object' && obj !== null && 'error_response_code' in obj
}

export const useErrorMessages = (): { errorMessages: ErrorMessages } => {
  const { t } = useTranslation()
  const errorMessages = {
    unsupported_image_format: (
      <div>
        {t('ui_products:Unsupported image format. The supported formats are')}
        <br />
        <br />- JPEG
        <br />- PNG
        <br />- WEBP
        <br />- HEIC/HEIF(
        {t(
          'ui_products:Only work if the original image was created from an iOS device',
        )}
        )
        <br />
      </div>
    ),
  } as const

  return { errorMessages }
}
