import { isProduction } from '@/utils/isProduction'
import { Helmet } from 'react-helmet'

const analyticsScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-TRBG5BZ180');
`

export const GoogleAnalytics = () => {
  if (!isProduction()) {
    return null
  }

  return (
    <Helmet>
      <script
        async={true}
        src={'https://www.googletagmanager.com/gtag/js?id=G-TRBG5BZ180'}
      />
      <script>{analyticsScript}</script>
    </Helmet>
  )
}
