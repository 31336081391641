import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Menu } from '@/features/api/types'
import { useMutation } from '@tanstack/react-query'

export function useMutationUpdateMenu() {
  return useMutation({
    mutationFn: (unsavedItem: Menu) => {
      const url = `${config.apiDomain}/v1/menus/${unsavedItem.id}/`
      return myKy.put(url, { json: unsavedItem }).json()
    },
  })
}
