import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { toQueryString } from '@/features/api/toQueryString'
import type { Genre, PaginatedResponse } from '@/features/api/types'
import type { GenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryGenres(condition: GenresQueryCondition = {}) {
  return useSuspenseQuery({
    queryKey: queryKeys.genres(condition),
    queryFn: () => {
      const queryString = toQueryString(condition)
      const url = `${config.apiDomain}/v1/genres/${queryString}`
      return myKy.get(url).json<PaginatedResponse<Genre>>()
    },
  })
}
