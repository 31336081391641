import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Genre, MaybeNew } from '@/features/api/types'
import { useMutation } from '@tanstack/react-query'

export function useMutationUpsertGenre() {
  return useMutation({
    mutationFn: (unsavedItem: MaybeNew<Genre>) => {
      if (unsavedItem.id) {
        const url = `${config.apiDomain}/v1/genres/${unsavedItem.id}/`
        return myKy.put(url, { json: unsavedItem }).json<Genre>()
      }
      const url = `${config.apiDomain}/v1/genres/`
      return myKy.post(url, { json: unsavedItem }).json<Genre>()
    },
  })
}
