import { AuthManager } from '@/features/auth/AuthManager'
import GlobalStyles from '@/features/layout/GlobalStyles'
import { theme } from '@/features/theme/theme'
import '@/libraries/firebase/initFirebase'
import { GoogleAnalytics } from '@/libraries/googleAnalytics/GoogleAnalytics'
import { MyErrorBoundary } from '@/libraries/reactQuery/MyErrorBoundary' // firebaseの初期化
import { renderRoutes } from '@/routes/routes'
import { useScreenRefresher } from '@/utils/useScreenRefresher'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { type FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ConfirmationProvider } from './features/confirmation/ConfirmationContext'
import LoadingScreen from './features/layout/LoadingScreen'
import { MySnackbarProvider } from './features/snackbar/MySnackbarProvider'
import { setupI18n } from './libraries/i18n/setupI18n'
import { ReactQuery } from './libraries/reactQuery/ReactQuery'
import { setupSentry } from './libraries/sentry/setupSentry'
import { StripeProvider } from './libraries/stripe/StripeProvider'

setupSentry()
setupI18n()

const App: FC = () => {
  useScreenRefresher()

  return (
    <MyErrorBoundary>
      <ReactQuery>
        <StyledEngineProvider injectFirst={true}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <MySnackbarProvider>
                <StripeProvider>
                  <ConfirmationProvider>
                    <Suspense fallback={<LoadingScreen />}>
                      <AuthManager>
                        <GlobalStyles />
                        <GoogleAnalytics />
                        {renderRoutes()}
                      </AuthManager>
                    </Suspense>
                  </ConfirmationProvider>
                </StripeProvider>
              </MySnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactQuery>
    </MyErrorBoundary>
  )
}

export default App
