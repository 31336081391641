import { useQueryVisitorSiteInfo } from '@/features/api/commonQueries/useQueryVisitorSiteInfo'
import Page from '@/features/layout/Page'
import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { css } from '@emotion/react'
import AssignmentIcon from '@mui/icons-material/Assignment'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DeploymentViewHeader } from './DeploymentViewHeader'
import { DeploymentViewNotes } from './DeploymentViewNotes'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  gridItem: css`
    height: 100%;
  `,
  imageContainer: (theme) => css`
    line-height: 0;
    display: inline-block;
    border: 1px solid ${theme.palette.divider};
    max-width: 150px;
  `,
  image: css`
    width: 100%;
  `,
  cardHeader: css`
    display: flex;
    align-items: center;
  `,
  cardHeaderIcon: css`
    font-size: 2rem;
    margin-right: 0.75rem;
  `,
} satisfies SxPropStyles

const DeploymentView: FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()

  const { qrcodeImageSrc, visitorSiteUrl, visitorSiteUrlPrint } =
    useQueryVisitorSiteInfo()

  const notes = {
    // Only for japanese
    qr: [
      'QRコードを読み取りやすくするため、ある程度大きさのあるサインスタンドなどを使って掲示することをお勧めします。',
      <span key={1}>
        例: A6サイズ(
        <a
          href="https://www.monotaro.com/p/4502/1568/"
          rel="noopener noreferrer"
          target="_blank"
        >
          くの字タイプ
        </a>
        、
        <a
          href="https://www.monotaro.com/p/1845/6296/"
          rel="noopener noreferrer"
          target="_blank"
        >
          T字タイプ
        </a>
        ) や写真Lサイズ(
        <a
          href="https://www.monotaro.com/p/4502/1559/"
          rel="noopener noreferrer"
          target="_blank"
        >
          くの字タイプ
        </a>
        )など。
      </span>,
      <span key={2}>
        Wordのテンプレートをダウンロードできます
        <span role="img" aria-label="pointing right">
          👉
        </span>
        <a href="/template_a6.zip">A6サイズ用</a>、
        <a href="/template_l.zip">Lサイズ用</a>
      </span>,
    ],
    paper: [
      <Trans
        key={1}
        i18nKey="ui_deploy:This feature only works in desktop Chrome browser."
      >
        <b />
      </Trans>,
      t(
        'ui_deploy:Each genre should fit on one page. If the genre overflows one page, reduce the print magnification or divide the genre.',
      ),
      t(
        'ui_deploy:Set the header and footer not to be printed on the print setting screen.',
      ),
    ],
  }

  return (
    <Page sx={styles.root} title={t('ui_deploy:Deployment')}>
      <Container maxWidth={false}>
        <DeploymentViewHeader />
        <Box mt={3} />

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={4}>
            <Card css={styles.gridItem}>
              <CardHeader
                title={
                  <Box css={styles.cardHeader}>
                    <span
                      css={styles.cardHeaderIcon}
                      role="img"
                      aria-label="smartphone"
                    >
                      📱
                    </span>
                    {t('ui_deploy:Use as in-store QR code menu')}
                  </Box>
                }
              />
              <Divider />
              <CardContent>
                <Box>
                  <Typography>
                    {t(
                      'ui_deploy:Deploy the following QR code to all the tables in the store.',
                    )}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Box sx={styles.imageContainer}>
                    <Box
                      component="img"
                      alt="qrcode"
                      src={qrcodeImageSrc}
                      sx={styles.imageContainer}
                    />
                  </Box>
                </Box>

                <Box mt={2}>
                  <Button
                    color="primary"
                    download={true}
                    fullWidth={true}
                    href={qrcodeImageSrc}
                    variant="contained"
                  >
                    {t('ui_deploy:Download QR code image')}
                  </Button>
                </Box>
                {i18n.language === 'ja' && (
                  <Box mt={2}>
                    <DeploymentViewNotes notes={notes.qr} />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item={true} xs={12} md={4}>
            <Card css={styles.gridItem}>
              <CardHeader
                title={
                  <div css={styles.cardHeader}>
                    <span
                      css={styles.cardHeaderIcon}
                      role="img"
                      aria-label="earth"
                    >
                      🌐
                    </span>
                    {t('ui_deploy:Use as Web menu')}
                  </div>
                }
              />
              <Divider />
              <CardContent>
                <Box mb={2}>
                  <Typography>
                    {t(
                      'ui_deploy:Paste the following link on Instagram, website, etc.',
                    )}
                  </Typography>
                </Box>
                <Box>
                  <OutlinedInput
                    fullWidth={true}
                    type="text"
                    value={visitorSiteUrl}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={t('ui_deploy:Copy URL')}>
                          <IconButton
                            onClick={async () => {
                              await navigator.clipboard.writeText(
                                visitorSiteUrl,
                              )
                              enqueueSnackbar(t('ui_deploy:Copied URL'), {
                                variant: 'success',
                              })
                            }}
                            size="large"
                          >
                            <AssignmentIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item={true} xs={12} md={4}>
            <Card css={styles.gridItem}>
              <CardHeader
                title={
                  <div css={styles.cardHeader}>
                    <span
                      css={styles.cardHeaderIcon}
                      role="img"
                      aria-label="notepad"
                    >
                      📋
                    </span>
                    {t('ui_deploy:Use as paper menu')}
                  </div>
                }
              />
              <Divider />
              <CardContent>
                <Typography>
                  {t(
                    'ui_deploy:You can print a simple paper menu from the following button.',
                  )}
                </Typography>
                <Box mt={2}>
                  <Button
                    color="primary"
                    fullWidth={true}
                    href={visitorSiteUrlPrint}
                    target="_blank"
                    variant="contained"
                  >
                    {t('ui_deploy:Preview paper menu')}
                  </Button>
                </Box>
                <Box mt={2}>
                  <DeploymentViewNotes notes={notes.paper} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default DeploymentView
