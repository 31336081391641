import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Genre, PaginatedResponse } from '@/features/api/types'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

/**
 * すべてのジャンルを取得する。ドロップダウンなどでマスタデータ的に使われる想定。
 */
export function useQueryAllGenres() {
  return useSuspenseQuery({
    queryKey: queryKeys.genresAll(),
    queryFn: () => {
      const url = `${config.apiDomain}/v1/genres/`
      return myKy.get(url).json<PaginatedResponse<Genre>>()
    },
  })
}
