import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

export const useMutationDeleteBackup = () => {
  return useMutation({
    mutationFn: (deletingId: string) => {
      const url = `${config.apiDomain}/v1/backups/${deletingId}/`
      return myKy.delete(url).json<void>()
    },
  })
}
