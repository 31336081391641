import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

export function useMutationDeleteGenre() {
  return useMutation({
    mutationFn: (deletingId: string) => {
      const url = `${config.apiDomain}/v1/genres/${deletingId}/`
      return myKy.delete(url).json()
    },
  })
}
