import type { CurrencyCode } from '@/features/currency/_do_not_edit_currencySettings'

//
// Models
//

export type Owner = {
  email: string
  id: string
  plan: PlanName
}

export type FontType = 'sans' | 'serif'

export type Menu = {
  id: string
  backup_id: string | null
  currency_code: CurrencyCode
  description: string
  font_type: FontType
  shop_name: string
  shop_postal_code: string
}

export const genreLayoutTypes = ['default', 'small_photo', 'no_photo'] as const
export type GenreLayoutType = (typeof genreLayoutTypes)[number]

export type Genre = {
  description: string
  id: string
  is_hidden: boolean
  layout_type: GenreLayoutType
  name: string
  order_num: number
}

export type Menuitem = {
  description: string
  description_detail: string
  genre: string
  id: string
  imgix_filename: string
  is_hidden: boolean
  name: string
  order_num: number
  price: number
  sold_out: boolean
}

export type Qrcode = {
  id: string
  menu: string
}

export type AccessCount = {
  id: string
  date: string
  access_count: number
  qrcode: string
}

export type Backup = {
  id: string
  memo: string
  created_at: string
}

//
// Entities
//

export type MyEntities = {
  genres: Genre
  menuitems: Menuitem
  menus: Menu
  owners: Owner
  qrcodes: Qrcode
  accessCounts: AccessCount
  backups: Backup
}

export type MyEntityName = keyof MyEntities

//
// Stripe
//

export type PlanName = 'starter' | 'basic' // | 'premium';

//
// Responses
//

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type PaginatedResponse<T = any> = {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

//
// Utils
//

export type Draft<T> = Omit<T, 'id' | 'created_at'>

export type MaybeNew<T> = Omit<T, 'id' | 'created_at'> & {
  id?: string
  created_at?: string
}
