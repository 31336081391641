import { apiRequestGetOwnerMy } from '@/features/auth/api/apiRequestGetOwnerMy'
import { mockedAuthorizedUserInfo } from '@/features/auth/mockedAuthorizedUserInfo'
import {
  type ExtraUserInfo,
  useAuthStatus,
  useSetAuthStatus,
} from '@/features/auth/state/authStatus'
import { firebaseAuth } from '@/libraries/firebase/initFirebase'
import { isPlaywrightTest } from '@/utils/isPlaywrightTest'
import { onAuthStateChanged } from 'firebase/auth'
import { type ReactNode, useEffect } from 'react'
import LoadingScreen from '../layout/LoadingScreen'

type Props = {
  children?: ReactNode
}

/**
 * 認証にまつわる複雑さを取り除いて、Firebaseから自前のコードに認証情報のコントロール権を
 * 取り戻すためのコンポーネント。以下の責務を追う。
 *
 * - Firebaseの非同期性をこの層で取り除き、配下のコンポーネントでは同期的に認証状態やユーザー情報を
 *   取得できるようにすることで、シンプルに実装ができる土台を整える。
 * - Firebase認証済みの場合には、追加のユーザー情報も必ずセットで利用できる状態を保証する。
 * - 初期ロード以降に認証状態に変化があったときに、適切に状態を更新する。
 * - シンプルさを保つため、リダイレクトの責務は負わない。認証切れ時のリダイレクトはProtectedRouteで行い、
 *   認証時のリダイレクトはサインイン画面のコードなどで行う。
 */
export const AuthManager = ({ children }: Props) => {
  const authStatus = useAuthStatus()
  const setAuthStatus = useSetAuthStatus()

  useEffect(() => {
    // onAuthStateChangedの中でhooks(e.g. useNavigateなど)を使うと面倒なことになる場合が多い。
    // hooksはなるべく使わず、コードも薄くしておくほうがいい。
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      async (firebaseUser) => {
        // 認証状態に変化があったときは必ず一度チェック中の状態にすることで、
        // 配下のコンポーネントをすべてアンマウントして事故を防ぐ
        setAuthStatus({ type: 'checking' })

        // playwrightテスト時にはすべてをすっ飛ばしてダミーのユーザー情報をセットして終了
        if (isPlaywrightTest()) {
          setAuthStatus({
            type: 'authorized',
            userInfo: mockedAuthorizedUserInfo,
          })
          return
        }

        // - 初期ロード時やリロード時に認証が切れている場合
        // - サインアウトした時
        // - 別タブでのログアウトなどにより認証が切れた時
        if (!firebaseUser) {
          setAuthStatus({ type: 'unauthorized' })
          return
        }

        // - 初期ロード時やリロード時に認証が生きている場合
        // - サインインした時
        let extraUserInfo: ExtraUserInfo
        try {
          extraUserInfo = await apiRequestGetOwnerMy()
        } catch (e) {
          // 失敗することは基本的に想定されない
          alert('ユーザー情報の取得に失敗しました')
          console.error(`ユーザー情報の取得に失敗しました: ${e}`)
          setAuthStatus({ type: 'unauthorized' })
          return
        }

        setAuthStatus({
          type: 'authorized',
          userInfo: {
            id: extraUserInfo.id,
            uid: firebaseUser.uid,
            avatar: firebaseUser.photoURL || '',
            email: firebaseUser.email || '',
            firebaseUser: firebaseUser,
            name: firebaseUser.displayName || firebaseUser.email || '',
            plan: extraUserInfo.plan,
            registration_date: extraUserInfo.created_at,
          },
        })
      },
    )

    // 発動することはない想定だが、念のため
    return unsubscribe
  }, [setAuthStatus])

  switch (authStatus.type) {
    case 'checking': {
      return <LoadingScreen />
    }
    case 'unauthorized':
    case 'authorized': {
      return <>{children}</>
    }
    default: {
      return authStatus satisfies never
    }
  }
}
