import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

export const useMutationRestoreBackup = () => {
  return useMutation({
    mutationFn: (backupId: string) => {
      const url = `${config.apiDomain}/v1/backups/${backupId}/restore/`
      return myKy.post(url).json<void>()
    },
  })
}
