import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

export type UploadMenuitemImageResponse = {
  filename: string
}

export function useMutationUploadMenuitemImage() {
  return useMutation({
    mutationFn: (file: File) => {
      const url = `${config.apiDomain}/v1/menuitems/upload_image/`
      const formData = new FormData()
      formData.append('image', file)
      const headers = {
        /* DO NOT SET THIS, WILL NOT WORK "Content-Type": "multipart/form-data", */
      }
      return myKy
        .post(url, { body: formData, headers })
        .json<UploadMenuitemImageResponse>()
    },
  })
}
