import { planSettings } from '@/features/account/planSettings'
import { useUserInfo } from '@/features/auth/state/authStatus'
import { useQueryTotalMenuitemCount } from '@/features/menuitemList/api/useQueryTotalMenuitemCount'
import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  className?: string
}

export const MenuitemListViewCautionItemCount = ({ ...rest }: Props) => {
  const totalCountQuery = useQueryTotalMenuitemCount()
  const { total_count: totalMenuitemCount } = totalCountQuery.data

  const { t } = useTranslation()

  const userInfo = useUserInfo()

  const currentPlan = userInfo.plan

  const { maxMenuitemCount } = planSettings[currentPlan]

  if (totalMenuitemCount <= maxMenuitemCount) {
    return null
  }

  return (
    <Box mt={3} {...rest}>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>
          {t('ui_products:The number of products has exceeded the limit.')}
        </AlertTitle>
        {t('ui_products:description of the limit count of products', {
          maxMenuitemCount,
          planName: `$t(plans:${planSettings[currentPlan].name})`,
        })}
        <br />
        {t(
          'ui_products:Reduce the number of products or subscribe to a higher plan.',
        )}
        <Box mt={1}>
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/app/account/"
            size="small"
          >
            {t('ui_products:Change plan')}
          </Button>
        </Box>
      </Alert>
    </Box>
  )
}
