import { useUserInfo } from '@/features/auth/state/authStatus'
import { addDays, max } from 'date-fns'

export const calcDyingDate = (
  registrationDateISO: string,
  // テスト時にインジェクトできるように
  today: Date = new Date(),
) => {
  const registrationDate = new Date(registrationDateISO)
  const defermentDate = addDays(registrationDate, 30)
  const mostFastestDate = new Date('2023-05-01T00:00:00.000Z')

  const dyingDate = max([defermentDate, mostFastestDate])
  const isDyingDatePassed = today > dyingDate

  return { dyingDate, isDyingDatePassed }
}

export const useDyingDate = () => {
  const userInfo = useUserInfo()

  const isPaymentRequired = userInfo.plan === 'starter'

  const { dyingDate, isDyingDatePassed } = calcDyingDate(
    userInfo.registration_date,
  )

  return { dyingDate, isPaymentRequired, isDyingDatePassed }
}
