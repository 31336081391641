import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Genre } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryGenre(id: string, enabled: boolean) {
  return useSuspenseQuery({
    queryKey: queryKeys.genre(id),
    queryFn: () => {
      if (!enabled) {
        return null
      }
      const url = `${config.apiDomain}/v1/genres/${id}/`
      return myKy.get(url).json<Genre>()
    },
    ...noCacheConfig,
  })
}
