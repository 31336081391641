import Page from '@/features/layout/Page'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportViewGeneral } from './SupportViewGeneral'
import { SupportViewHeader } from './SupportViewHeader'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
}
const SupportView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.root} title={t('Support')}>
      <Container maxWidth={false}>
        <SupportViewHeader />
        <Box mt={3}>
          <SupportViewGeneral />
        </Box>
      </Container>
    </Page>
  )
}

export default SupportView
