import { config } from '@/config'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import type { ReactNode } from 'react'

const stripePromise = loadStripe(config.stripePublicKey)

// 配下のすべてのコンポーネント内で Stripe オブジェクトにアクセスできるようにするためのプロバイダー
// https://docs.stripe.com/stripe-js/react#elements-%E3%83%97%E3%83%AD%E3%83%90%E3%82%A4%E3%83%80
export const StripeProvider = ({ children }: { children: ReactNode }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}
