import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { PaginatedResponse, Qrcode } from '@/features/api/types'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

export const useQueryVisitorSiteInfo = () => {
  const qrcodesQuery = useSuspenseQuery({
    queryKey: queryKeys.qrcodes(),
    queryFn: () => {
      /**
       * TODO: このエンドポイントから取る情報じゃない気がする。整理したい。
       */
      const url = `${config.apiDomain}/v1/qrcodes/`
      return myKy.get(url).json<PaginatedResponse<Qrcode>>()
    },
  })

  const {
    data: { results: qrcodes },
  } = qrcodesQuery

  // For now, there is only one qrcode per user.
  const qrcode = qrcodes[0]
  invariant(qrcode)

  const qrcodeImageSrc = `${config.apiDomain}/v1/qrcodes/${qrcode.id}/image/`
  const visitorSiteUrl = `${config.visitorSiteDomain}/qrcode/${qrcode.id}`
  const visitorSiteUrlPrint = `${visitorSiteUrl}?isPrintPreview=true`

  return {
    qrcode,
    qrcodeImageSrc,
    visitorSiteUrl,
    visitorSiteUrlPrint,
  }
}
