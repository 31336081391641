import { isProduction } from '@/utils/isProduction'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Sentry from '@sentry/react'

export const setupSentry = () => {
  if (!isProduction()) {
    return
  }

  Sentry.init({
    dsn: 'https://4fedc64e90e447dbae2e750ec47fe7de@o151410.ingest.sentry.io/5665799',
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
    ],
  })
}
