import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Backup, PaginatedResponse } from '@/features/api/types'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryBackups = () => {
  const result = useSuspenseQuery({
    queryKey: queryKeys.backups(),
    queryFn: () => {
      return myKy
        .get(`${config.apiDomain}/v1/backups/`)
        .json<PaginatedResponse<Backup>>()
    },
  })

  return {
    // ページネーションは使用しないのでいきなり結果群を返しちゃう
    backups: result.data.results,
  }
}
