//
//🚨全体的に整理が必要だがGraphQLに移行したら不要になるので一旦放置
//

export type MaybeRecordsQueryCondition = {
  order?: 'asc' | 'desc'
  orderBy?: string
  searchWord?: string
}

export const toQueryString = (condition: MaybeRecordsQueryCondition) => {
  const { order, orderBy, searchWord } = condition
  const queryStrings = []

  if (orderBy && order) {
    const prefix = order === 'desc' ? '-' : ''
    queryStrings.push(`ordering=${prefix}${orderBy}`)
  }

  if (searchWord) {
    queryStrings.push(`search=${searchWord}`)
  }

  let queryString = ''
  if (queryStrings.length > 0) {
    queryString += `?${queryStrings.join('&')}`
  }
  return queryString
}
