import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

type BulkDeleteResponse = {
  count: number
  ids: string[]
}

export function useMutationBulkDeleteGenres() {
  return useMutation({
    mutationFn: (deletingIds: string[]) => {
      const url = `${config.apiDomain}/v1/genres/bulk_delete/`
      const payload = { ids: deletingIds }
      return myKy.post(url, { json: payload }).json<BulkDeleteResponse>()
    },
  })
}
