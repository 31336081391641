import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Backup } from '@/features/api/types'
import { useMutation } from '@tanstack/react-query'

export const useMutationUpdateBackup = () => {
  return useMutation({
    mutationFn: (unsavedItem: Backup) => {
      const url = `${config.apiDomain}/v1/backups/${unsavedItem.id}/`
      return myKy.put(url, { json: unsavedItem }).json<void>()
    },
  })
}
