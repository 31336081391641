import { YouTube } from './mdxComponents/YouTube'

#### 2024-09-10

モバイル端末から商品の価格を入力した際に、文字が重複して入力される問題を修正しました。

#### 2024-08-16

- メニュー一覧画面のジャンル列をクリックすると、そのジャンルの詳細ページに遷移できるようになりました。
<YouTube url="https://www.youtube.com/embed/HpRjrS_BKJg?si=sQ2g70VipazTyXs0" />
