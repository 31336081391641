import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

export type Order = 'asc' | 'desc'

export type MenuitemsQueryCondition = {
  order?: Order
  orderBy?: string
  searchWord?: string
}

const initialCondition: MenuitemsQueryCondition = {
  order: 'asc',
  orderBy: 'genre',
  searchWord: '',
}

const menuitemsQueryConditionAtom =
  atom<MenuitemsQueryCondition>(initialCondition)

/**
 * 商品一覧画面のクエリ条件を保持するカスタムフック
 */
export const useMenuitemsQueryCondition = () => {
  const [condition, setCondition] = useAtom(menuitemsQueryConditionAtom)

  const setOrder = useCallback(
    (property: string, order: Order) => {
      setCondition((prev) => ({
        ...prev,
        order,
        orderBy: property,
      }))
    },
    [setCondition],
  )

  const setSearchWord = useCallback(
    (word: string) => {
      setCondition((prev) => ({
        ...prev,
        searchWord: word.trim(),
      }))
    },
    [setCondition],
  )

  return {
    condition,
    setOrder,
    setSearchWord,
  }
}
