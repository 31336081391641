import { type ReactNode, createContext, useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import {
  ConfirmationDialog,
  type ConfirmationOptions,
} from './ConfirmationDialog'

type ContextValue = {
  confirm: (options: ConfirmationOptions) => Promise<boolean>
}

const ConfirmationContext = createContext<ContextValue>({
  confirm: Promise.resolve,
})

type ConfirmationStackItem = {
  id: string
  options: ConfirmationOptions
  resolve: (arg: boolean) => void
}

export const ConfirmationProvider = ({ children }: { children: ReactNode }) => {
  const [confirmationStack, setConfirmationStack] = useState<
    ConfirmationStackItem[]
  >([])

  const confirm = (options: ConfirmationOptions) => {
    return new Promise<boolean>((resolve) => {
      const confirmationStackItem = { options, resolve, id: uuid() }
      setConfirmationStack((current) => [...current, confirmationStackItem])
    })
  }

  const renderDialogs = () =>
    confirmationStack.map((thisConfirmation) => (
      <ConfirmationDialog
        key={thisConfirmation.id}
        // @ts-expect-error TS2345
        onDecision={(decision) => thisConfirmation.resolve(decision)}
        onDissmissable={() => {
          setConfirmationStack((existingConfirmations) =>
            existingConfirmations.filter(
              (existingConfirmation) =>
                existingConfirmation.id !== thisConfirmation.id,
            ),
          )
        }}
        {...thisConfirmation.options}
      />
    ))

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {renderDialogs()}
      {children}
    </ConfirmationContext.Provider>
  )
}

export const useConfirmation = () => useContext(ConfirmationContext)
