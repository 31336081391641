import { ForcePaymentNotification } from '@/features/account/forcePayment/ForcePaymentNotification'
import { useDyingDate } from '@/features/account/forcePayment/useDyingDate'
import Page from '@/features/layout/Page'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PaidPlanOnly } from '@/components/PaidPlanOnly'
import { MenuitemListViewCautionItemCount } from '@/features/menuitemList/MenuitemListViewCautionItemCount'
import { MenuitemListViewCautionMailVerification } from '@/features/menuitemList/MenuitemListViewCautionMailVerification'
import { MenuitemListViewHeader } from '@/features/menuitemList/MenuitemListViewHeader'
import { MenuitemListViewResults } from '@/features/menuitemList/MenuitemListViewResults'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const MenuitemListView: FC = () => {
  const { t } = useTranslation()

  const { isDyingDatePassed, isPaymentRequired } = useDyingDate()

  const isUnpaidUser = isDyingDatePassed && isPaymentRequired

  const paidUserContent = (
    <>
      <MenuitemListViewHeader />
      <MenuitemListViewCautionItemCount />
      <MenuitemListViewCautionMailVerification />
      <Box mt={3}>
        <MenuitemListViewResults />
      </Box>
    </>
  )

  const unPaidUserContent = (
    <>
      <ForcePaymentNotification />
      <div style={{ position: 'relative', marginTop: '3rem' }}>
        {paidUserContent}
        <PaidPlanOnly padding={0} />
      </div>
    </>
  )

  return (
    <Page sx={styles.container} title={t('Products')}>
      <Container maxWidth={false}>
        {isUnpaidUser ? unPaidUserContent : paidUserContent}
      </Container>
    </Page>
  )
}

export default MenuitemListView
