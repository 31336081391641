import type { Genre } from '@/features/api/types'
import { useQueryGenre } from '@/features/genreDetail/api/useQueryGenre'
import Page from '@/features/layout/Page'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { GenreDetailViewForm } from './GenreDetailViewForm'
import { GenreDetailViewHeader } from './GenreDetailViewHeader'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const GenreDetailView: FC = () => {
  const { t } = useTranslation()

  const match = useMatch('/app/management/genres/:genreId')
  invariant(match?.params.genreId, 'genreIdが見つかりません')
  const { genreId } = match.params

  const isNew = genreId === 'new'

  const { data: genre } = useQueryGenre(genreId, !isNew)

  let genreName: string
  let existingGenre: Genre | undefined

  if (isNew) {
    genreName = t('ui_genres:New genre')
  } else {
    invariant(genre)
    genreName = genre.name
    existingGenre = genre
  }

  return (
    <Page sx={styles.container} title={t('Genre')}>
      <Container maxWidth={false}>
        <GenreDetailViewHeader isNew={isNew} genreName={genreName} />
      </Container>
      <Box mt={3}>
        <Container maxWidth={false}>
          <GenreDetailViewForm isNew={isNew} existingGenre={existingGenre} />
        </Container>
      </Box>
    </Page>
  )
}

export default GenreDetailView
