import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { toQueryString } from '@/features/api/toQueryString'
import type { Menuitem, PaginatedResponse } from '@/features/api/types'
import type { MenuitemsQueryCondition } from '@/features/menuitemList/useMenuitemsQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryMenuitems = (condition: MenuitemsQueryCondition) => {
  return useSuspenseQuery({
    queryKey: queryKeys.menuitems(condition),
    queryFn: () => {
      const queryString = toQueryString(condition)
      const url = `${config.apiDomain}/v1/menuitems/${queryString}`
      return myKy.get(url).json<PaginatedResponse<Menuitem>>()
    },
  })
}
