import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import {
  type ChangeEvent,
  type FC,
  startTransition,
  useEffect,
  useState,
} from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { useQueryAllGenres } from '@/features/api/commonQueries/useQueryAllGenres'
import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { CurrencyText } from '@/features/currency/_do_not_edit_CurrencyText'
import { useQueryMenu } from '@/features/menuSetting/api/useQueryMenu'
import { useMutationDeleteMenuitems } from '@/features/menuitemList/api/useMutationDeleteMenuitems'
import { useQueryMenuitems } from '@/features/menuitemList/api/useQueryMenuitems'
import { useMenuitemsQueryCondition } from '@/features/menuitemList/useMenuitemsQueryCondition'
import { getImgixSrc } from '@/libraries/imgix/getImgixSrc'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useDebounce } from '@/utils/useDebounce'

interface Props {
  className?: string
}

const styles = {
  queryField: {
    maxWidth: 500,
    width: '100%',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: '7px',
    paddingRight: 0.5,
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'background.default',
  },
  bulkActionCheckbox: {
    margin: '-3px 0',
  },
  bulkAction: {
    marginLeft: 2,
  },
  photo: {
    height: 42,
    width: 42,
    marginRight: 2,
  },
}

export const MenuitemListViewResults: FC<Props> = ({ className, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirmation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    condition: queryCondition,
    setOrder,
    setSearchWord,
  } = useMenuitemsQueryCondition()

  const [selectedMenuitems, setSelectedMenuitems] = useState<string[]>([])

  const [searchInputValue, setSearchInputValue] = useState<string>(
    queryCondition.searchWord || '',
  )
  const debouncedSearchWord = useDebounce<string>(searchInputValue, 500)
  useEffect(() => {
    startTransition(() => {
      setSearchWord(debouncedSearchWord)
    })
  }, [debouncedSearchWord, setSearchWord])

  const createSortLabelProps = (property: string) => {
    return {
      active: queryCondition.orderBy === property,
      direction: queryCondition.order,
      onClick: () => {
        const isPropertyChanged = queryCondition.orderBy !== property
        if (isPropertyChanged) {
          startTransition(() => {
            setOrder(property, 'asc')
          })
        } else {
          startTransition(() => {
            setOrder(property, queryCondition.order === 'asc' ? 'desc' : 'asc')
          })
        }
      },
    }
  }

  const menuQuery = useQueryMenu()
  const { currency_code: currencyCode } = menuQuery.data

  const menuitemsQuery = useQueryMenuitems(queryCondition)
  const {
    data: { results: menuitems },
  } = menuitemsQuery

  const genresAllQuery = useQueryAllGenres()
  const { results: genres } = genresAllQuery.data

  const { isPending: isDeleting, mutate: deletionMutate } =
    useMutationDeleteMenuitems()

  const handleSelectAllMenuitems = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedMenuitems(
      event.target.checked ? menuitems.map((menuitem) => menuitem.id) : [],
    )
  }

  const handleSelectOneMenuitem = (menuitemId: string): void => {
    if (selectedMenuitems.includes(menuitemId)) {
      setSelectedMenuitems((prevSelected) =>
        prevSelected.filter((id) => id !== menuitemId),
      )
    } else {
      setSelectedMenuitems((prevSelected) => [...prevSelected, menuitemId])
    }
  }

  const deleteSelectedItems = async () => {
    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_products:multiple products deletion confirmation', {
        count: selectedMenuitems.length,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    deletionMutate(selectedMenuitems, {
      onSuccess: (result) => {
        queryClient.invalidateQueries({ queryKey: queryKeys.menuitemsAll() })
        const message = t('ui_products:Deleted products', {
          count: result.count,
        })
        enqueueSnackbar(message, {
          variant: 'success',
        })
        setSelectedMenuitems([])
      },
    })
  }

  const enableBulkOperations = selectedMenuitems.length > 0
  const isSomeMenuitemSelected =
    selectedMenuitems.length > 0 && selectedMenuitems.length < menuitems.length
  const isEveryMenuitemSelected =
    menuitems.length > 0 && selectedMenuitems.length === menuitems.length
  const isFiltering = !!queryCondition.searchWord
  const hasMenuItems = menuitems.length > 0

  if (!(isFiltering || hasMenuItems)) {
    return (
      <Typography color="textPrimary">
        {t(
          'ui_products:No products have been registered yet. Click the button to register your first product.',
        )}
      </Typography>
    )
  }

  return (
    <Card className={className} {...rest}>
      <Box p={2}>
        <TextField
          sx={styles.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: searchInputValue ? (
              <IconButton
                onClick={() => {
                  setSearchInputValue('')
                  startTransition(() => {
                    // skip debounsing and show all items immediately
                    setSearchWord('')
                  })
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ) : null,
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value)
          }}
          placeholder={t('Search')}
          size="small"
          value={searchInputValue}
          variant="outlined"
        />
      </Box>
      {enableBulkOperations && (
        <Box sx={styles.bulkOperations}>
          <Box sx={styles.bulkActions}>
            <Checkbox
              checked={isEveryMenuitemSelected}
              sx={styles.bulkActionCheckbox}
              indeterminate={isSomeMenuitemSelected}
              onChange={handleSelectAllMenuitems}
            />
            <Button
              disabled={isDeleting}
              variant="outlined"
              sx={styles.bulkAction}
              onClick={deleteSelectedItems}
              size="small"
            >
              {t('Delete')}
            </Button>
          </Box>
        </Box>
      )}
      <Paper
        css={css`
          overflow-x: auto;
        `}
      >
        <Box minWidth={700}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isEveryMenuitemSelected}
                    indeterminate={isSomeMenuitemSelected}
                    onChange={handleSelectAllMenuitems}
                  />
                </TableCell>
                <TableCell style={{ minWidth: '250px' }} sortDirection="desc">
                  <TableSortLabel {...createSortLabelProps('name')}>
                    {t('Name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '250px' }}>
                  <TableSortLabel {...createSortLabelProps('description')}>
                    {t('Additional description')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '130px' }}>
                  <TableSortLabel {...createSortLabelProps('genre')}>
                    {t('Genres')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{ minWidth: '140px' }}>
                  <TableSortLabel {...createSortLabelProps('price')}>
                    {t('Price')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{ minWidth: '130px' }}>
                  <TableSortLabel {...createSortLabelProps('order_num')}>
                    {t('Sort number')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '110px' }}>
                  <TableSortLabel {...createSortLabelProps('is_hidden')}>
                    {t('Hidden')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '120px' }}>
                  <TableSortLabel {...createSortLabelProps('sold_out')}>
                    {t('Sold out')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menuitems.map((menuitem) => {
                const isMenuitemSelected = selectedMenuitems.includes(
                  menuitem.id,
                )
                const genreName =
                  genres.find((genre) => genre.id === menuitem.genre)?.name ||
                  `(${t('Unspecified')})`

                return (
                  <TableRow
                    hover={true}
                    key={menuitem.id}
                    selected={isMenuitemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isMenuitemSelected}
                        onChange={() => handleSelectOneMenuitem(menuitem.id)}
                        value={isMenuitemSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          sx={styles.photo}
                          src={getImgixSrc(menuitem.imgix_filename, {
                            width: 80,
                          })}
                          variant="rounded"
                        />
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/app/management/menuitems/${menuitem.id}`}
                          variant="h6"
                        >
                          {menuitem.name}
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell>{menuitem.description}</TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/app/management/genres/${menuitem.genre}`}
                        variant="h6"
                      >
                        {genreName}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyText
                        currencyCode={currencyCode}
                        value={menuitem.price}
                      />
                    </TableCell>
                    <TableCell align="right">{menuitem.order_num}</TableCell>
                    <TableCell>{menuitem.is_hidden ? t('Yes') : ''}</TableCell>
                    <TableCell>{menuitem.sold_out ? t('Yes') : ''}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Card>
  )
}
