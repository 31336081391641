import { config } from '@/config'
import type { PlanName } from '@/features/api/types'

export type PlanSetting = {
  color: string
  features: string[]
  featuresJa: string[]
  id: PlanName
  name: string
  nameJa: string
  maxBackupCount: number
  maxMenuitemCount: number
  price: string
  priceId?: string
}

type PlanSettings = {
  [key in PlanName]: PlanSetting
}

export const planSettings: PlanSettings = {
  starter: {
    color: '#2e9942',
    features: [
      'Up to 100 products',
      '38 Currencies (Can be added, please contact support)',
      'Generate QR-code and URL Automatically',
      'Print out paper menu',
      'Zoom images',
      'Show detailed descriptions when zooming',
      'Categorize with genres',
      'Smooth navigation between genres',
      'Favorites',
      'Choose from 2 layouts (Photo or w/o Photo)  for each genre',
      'Put general description about your shop',
      'Smartphone / Tablet / Desktop',
      'SEO',
      'Access stats',
    ],
    featuresJa: [
      '100 個までの商品',
      '38種類の通貨(追加可能)',
      'QRコード/URLの自動生成',
      '紙メニューの印刷出力',
      '写真のズームと詳細説明の表示',
      'ジャンル分けとナビゲーション',
      'お気に入り',
      '写真ありレイアウト・写真なしレイアウトをジャンルごとに選択可',
      'お店の紹介文などの記載',
      'スマホ・タブレット・デスクトップに対応',
      'SEO対策',
      'アクセス統計機能',
    ],
    id: 'starter',
    name: 'Starter plan',
    nameJa: 'スタータープラン',
    maxBackupCount: 5,
    maxMenuitemCount: 300,
    price: '¥0',
  },
  basic: {
    color: '#E65C00',
    features: [
      'Starter features, plus',
      'Up to 300 products',
      'Display as sold-out',
      'Temporarily hide the product',
      '5 Backups',
      '20 Fonts',
    ],
    featuresJa: [
      'スタータープランの全ての特徴に加え',
      '300 個までの商品',
      '売り切れ表示機能',
      '一時非表示機能',
      '5個までのバックアップ',
      '全20種類のフォント',
    ],
    id: 'basic',
    name: 'Basic plan',
    nameJa: 'ベーシックプラン',
    maxBackupCount: 5,
    maxMenuitemCount: 300,
    price: '$3',
    priceId: config.priceIdBasic,
  },
  // premium: {
  //   color: '#152D36',
  //   features: ['Basic features, plus', 'Up to 300 products', '10 Backups'],
  //   featuresJa: [
  //     'ベーシックプランの全ての特徴に加え',
  //     '300 個までの商品',
  //     '10個までのバックアップ',
  //   ],
  //   id: 'premium',
  //   name: 'Premium plan',
  //   nameJa: 'プレミアムプラン',
  //   maxBackupCount: 10,
  //   maxMenuitemCount: 300,
  //   price: '¥1,500',
  //   priceId: config.priceIdPremium,
  // },
}
