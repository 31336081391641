import { useDyingDate } from '@/features/account/forcePayment/useDyingDate'
import { Alert, AlertTitle, Box, Link } from '@mui/material'
import { format } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'

export const ForcePaymentNotification = () => {
  const { dyingDate } = useDyingDate()
  const dyingDatePretty = format(dyingDate, 'yyyy年M月d日')

  return (
    <Box mt={3}>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>有料プランへのご登録をお願いいたします</AlertTitle>
        {dyingDatePretty}で無料試用期間が終了しました。お手数ですが
        <Link
          variant="h5"
          color="inherit"
          to="/app/account"
          component={RouterLink}
          underline="always"
        >
          アカウント
        </Link>
        から有料プランへのご登録をお願いいたします。
      </Alert>
    </Box>
  )
}
