import { useQueryVisitorSiteInfo } from '@/features/api/commonQueries/useQueryVisitorSiteInfo'
import { useMenuPreviewer } from '@/features/menuPreviewer/useMenuPreviewer'
import {
  IconButton,
  SvgIcon,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import type { FC } from 'react'
import { Eye as EyeIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'

export const PreviewMenuButton: FC = () => {
  const { visitorSiteUrl } = useQueryVisitorSiteInfo()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { open: openMenuPreviewer } = useMenuPreviewer()

  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let iconButtonProps

  if (isMobile) {
    iconButtonProps = {
      href: visitorSiteUrl,
      target: '_blank',
    }
  } else {
    iconButtonProps = {
      onClick: () => {
        openMenuPreviewer()
      },
    }
  }

  return (
    <Tooltip title={t('Preview menu')}>
      <IconButton {...iconButtonProps} color="inherit" size="large">
        <SvgIcon fontSize="small">
          <EyeIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  )
}
