import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useMutation } from '@tanstack/react-query'

export function useMutationCreatePortalSession() {
  return useMutation({
    mutationFn: async () => {
      const url = `${config.apiDomain}/v1/stripe/create_portal_session/`

      // レスポンスの型が楽観的
      const { url: portalUrl } = await myKy.post(url).json<{ url: string }>()

      window.location.assign(portalUrl)
    },
  })
}
