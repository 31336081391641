import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Menuitem } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryMenuitem(id: string, enabled: boolean) {
  return useSuspenseQuery({
    queryKey: queryKeys.menuitem(id),
    queryFn: () => {
      if (!enabled) {
        return null
      }
      const url = `${config.apiDomain}/v1/menuitems/${id}/`
      return myKy.get(url).json<Menuitem>()
    },
    ...noCacheConfig,
  })
}
