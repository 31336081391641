import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useSuspenseQuery } from '@tanstack/react-query'

type Response = { total_count: number }

export function useQueryTotalMenuitemCount() {
  return useSuspenseQuery({
    queryKey: queryKeys.menuitemsCount(),
    queryFn: () => {
      const url = `${config.apiDomain}/v1/menuitems/total_count/`
      return myKy.get(url).json<Response>()
    },
  })
}
