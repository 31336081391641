import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import {
  type ChangeEvent,
  type FC,
  startTransition,
  useEffect,
  useState,
} from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { useMutationBulkDeleteGenres } from '@/features/genreList/api/useMutationBulkDeleteGenres'
import { useQueryGenres } from '@/features/genreList/api/useQueryGenres'
import { useGenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useDebounce } from '@/utils/useDebounce'

interface Props {
  className?: string
}

const styles = {
  queryField: {
    maxWidth: 500,
    width: '100%',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: '7px',
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'background.default',
  },
  bulkActionCheckbox: {
    margin: '-3px 0',
  },
  bulkAction: {
    marginLeft: 2,
  },
}

export const GenreListViewResults: FC<Props> = ({ className, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    condition: queryCondition,
    setOrder,
    setSearchWord,
  } = useGenresQueryCondition()
  const { confirm } = useConfirmation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedGenres, setSelectedGenres] = useState<string[]>([])

  const [searchInputValue, setSearchInputValue] = useState<string>(
    // restore previous value on navigation
    queryCondition.searchWord || '',
  )
  const debouncedSearchWord = useDebounce<string>(searchInputValue, 500)
  useEffect(() => {
    startTransition(() => {
      setSearchWord(debouncedSearchWord)
    })
  }, [debouncedSearchWord, setSearchWord])

  const createSortLabelProps = (property: string) => {
    return {
      active: queryCondition.orderBy === property,
      direction: queryCondition.order,
      onClick: () => {
        const isPropertyChanged = queryCondition.orderBy !== property
        if (isPropertyChanged) {
          startTransition(() => {
            setOrder(property, 'asc')
          })
          return
        }
        startTransition(() => {
          setOrder(property, queryCondition.order === 'asc' ? 'desc' : 'asc')
        })
      },
    }
  }

  const genresQuery = useQueryGenres(queryCondition)
  const {
    data: { results: genres },
  } = genresQuery

  const { isPending: isDeleting, mutate: deletionMutate } =
    useMutationBulkDeleteGenres()

  const handleSelectAllGenres = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedGenres(
      event.target.checked ? genres.map((genre) => genre.id) : [],
    )
  }

  const handleSelectOneGenre = (genreId: string): void => {
    if (selectedGenres.includes(genreId)) {
      setSelectedGenres((prevSelected) =>
        prevSelected.filter((id) => id !== genreId),
      )
    } else {
      setSelectedGenres((prevSelected) => [...prevSelected, genreId])
    }
  }

  const deleteSelectedItems = async () => {
    const message = t('ui_genres:multiple genres deletion confirmation', {
      count: selectedGenres.length,
    })
    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: message,
      title: t('Deletion'),
      variant: 'danger',
    })
    if (yes) {
      deletionMutate(selectedGenres, {
        onSuccess: (result) => {
          queryClient.invalidateQueries({ queryKey: queryKeys.genresAll() })
          const message = t('ui_genres:Deleted genres', {
            count: result.count,
          })
          enqueueSnackbar(message, { variant: 'success' })
          setSelectedGenres([])
        },
      })
    }
  }

  const enableBulkOperations = selectedGenres.length > 0
  const isSomeGenreSelected =
    selectedGenres.length > 0 && selectedGenres.length < genres.length
  const isEveryGenreSelected =
    genres.length > 0 && selectedGenres.length === genres.length
  const isFiltering = !!queryCondition.searchWord
  const hasGenres = genres.length > 0

  if (!(isFiltering || hasGenres)) {
    return (
      <Typography color="textPrimary">
        {t(
          'ui_genres:No genres have been registered yet. Click the button to register your first genre.',
        )}
      </Typography>
    )
  }

  return (
    <Card className={className} {...rest}>
      <Box p={2}>
        <TextField
          sx={styles.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: searchInputValue ? (
              <IconButton
                onClick={() => {
                  setSearchInputValue('')
                  // skip debounsing and show all items immediately
                  setSearchWord('')
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ) : null,
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value)
          }}
          placeholder={t('Search')}
          size="small"
          value={searchInputValue}
          variant="outlined"
        />
      </Box>
      {enableBulkOperations && (
        <Box sx={styles.bulkOperations}>
          <Box sx={styles.bulkActions}>
            <Checkbox
              checked={isEveryGenreSelected}
              sx={styles.bulkActionCheckbox}
              indeterminate={isSomeGenreSelected}
              onChange={handleSelectAllGenres}
            />
            <Button
              disabled={isDeleting}
              variant="outlined"
              sx={styles.bulkAction}
              onClick={deleteSelectedItems}
              size="small"
            >
              {t('Delete')}
            </Button>
          </Box>
        </Box>
      )}
      <Paper
        css={css`
          overflow-x: auto;
        `}
      >
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isEveryGenreSelected}
                    indeterminate={isSomeGenreSelected}
                    onChange={handleSelectAllGenres}
                  />
                </TableCell>
                <TableCell style={{ minWidth: '100px' }} sortDirection="desc">
                  <TableSortLabel {...createSortLabelProps('name')}>
                    {t('Name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '250px' }} sortDirection="desc">
                  <TableSortLabel {...createSortLabelProps('description')}>
                    {t('Additional description')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" style={{ minWidth: '130px' }}>
                  <TableSortLabel {...createSortLabelProps('order_num')}>
                    {t('Sort number')}
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: '100px' }}>
                  <TableSortLabel {...createSortLabelProps('is_hidden')}>
                    {t('Hidden')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {genres.map((genre) => {
                const isGenreSelected = selectedGenres.includes(genre.id)

                return (
                  <TableRow
                    hover={true}
                    key={genre.id}
                    selected={isGenreSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isGenreSelected}
                        onChange={() => handleSelectOneGenre(genre.id)}
                        value={isGenreSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box minHeight={40} display="flex" alignItems="center">
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/app/management/genres/${genre.id}`}
                          variant="h6"
                        >
                          {genre.name}
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell>{genre.description}</TableCell>
                    <TableCell align="right">{genre.order_num}</TableCell>
                    <TableCell>{genre.is_hidden ? t('Yes') : ''}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Card>
  )
}
