import { css } from '@emotion/react'
import type { FC } from 'react'
import { strongShadows } from '../theme/shadows'

interface LogoProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  [key: string]: any
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <div
      css={css`
        background: white;
        border-radius: 50%;
        box-shadow: ${strongShadows[2]};
        height: 42px;
        line-height: 0;
        overflow: hidden;
        padding: 4px;
        width: 42px;
      `}
      {...props}
    >
      {/* biome-ignore lint/a11y/useAltText: <explanation> */}
      <img
        css={css`
          height: 100%;
          width: 100%;
        `}
        alt="Logo"
        src="/static/logo.svg"
        {...props}
      />
    </div>
  )
}

export default Logo
