import { SubscriptionNew } from '@/features/account/subscription/SubscriptionNew'
import { SubscriptionUpdate } from '@/features/account/subscription/SubscriptionUpdate'
import type { PlanName } from '@/features/api/types'
import { useUserInfo } from '@/features/auth/state/authStatus'
import { css } from '@emotion/react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  currentPlan: PlanName
  className?: string
}

export const Subscription: FC<Props> = ({ currentPlan, ...rest }) => {
  const { t } = useTranslation()
  const userInfo = useUserInfo()

  const { emailVerified } = userInfo.firebaseUser

  if (!emailVerified) {
    return (
      <Typography>{t('ui_account:Verify email before upgrade')}</Typography>
    )
  }

  return (
    <Card {...rest}>
      <CardHeader title={t('ui_account:Manage subscription')} />
      <Divider />
      <CardContent>
        <Typography>現在のプラン: {currentPlan}</Typography>
        <SubscriptionNew css={styles.row} />
        <SubscriptionUpdate css={styles.row} />
      </CardContent>
    </Card>
  )
}

const styles = {
  row: css`
    margin-top: 1rem;
  `,
}
