import invariant from 'tiny-invariant'

type Config = {
  apiDomain: string
  firebaseApiKey: string
  firebaseAppId: string
  firebaseAuthDomain: string
  firebaseProjectId: string
  imgixDomain: string
  priceIdBasic: string
  // priceIdPremium: string;
  stripePublicKey: string
  visitorSiteDomain: string
}

// configの型を保証するためのもの。なんかもっといい方法ないんかな...
const validAppEnvs = ['local', 'production'] as const
type ValidAppEnv = (typeof validAppEnvs)[number]
const isValidAppEnv = (value: string): value is ValidAppEnv =>
  validAppEnvs.find((validAppEnv) => validAppEnv === value) !== undefined
// biome-ignore lint/complexity/useLiteralKeys: <explanation>
const APP_ENV = import.meta.env['VITE_APP_ENV'] || 'local'
invariant(isValidAppEnv(APP_ENV))

const configs: { [key in ValidAppEnv]: Config } = {
  local: {
    apiDomain: 'http://localhost:8000',
    // モバイル端末からの検証時は以下と差し替える
    // apiDomain: 'http://tam-mac.local:8000',
    firebaseApiKey: 'AIzaSyABEa7bwHGGLwk1PdZ-2SiwwM8wptO5dTM',
    firebaseAppId: '1:113773671771:web:780f3a927e821003c2167e',
    firebaseAuthDomain: 'suzume-local.firebaseapp.com',
    firebaseProjectId: 'suzume-local',
    imgixDomain: 'suzume-local.imgix.net',
    priceIdBasic: 'price_1MsavDLKBrDEnd60SYZg2EWl', // test
    // priceIdPremium: 'price_1IUAVTLKBrDEnd60s0jUIE0b', // test
    stripePublicKey:
      'pk_test_51ILI5WLKBrDEnd60RO85sBdnRLQOjF4onPBhx1C5rd8SJXEeyXb49ZUMNUFMgHOilugQaV6mczcbPQ92K62UpUBI00v2LjbvWc',
    visitorSiteDomain: 'http://localhost:3000',
  },
  production: {
    apiDomain: 'https://api.owner.goodmenu.io',
    firebaseApiKey: 'AIzaSyDJNv0xzhEoxtSKcnN2tkQSyKvohIboaak',
    firebaseAppId: '1:709930835777:web:14b4f882d916fa825e4bc0',
    firebaseAuthDomain: 'goodmenu-prod.firebaseapp.com',
    firebaseProjectId: 'goodmenu-prod',
    imgixDomain: 'goodmenu-prod.imgix.net',
    priceIdBasic: 'price_1Msb7LLKBrDEnd60oNJ4wsk6', // prod
    // priceIdPremium: 'price_1IVWNVLKBrDEnd609m2oK3VH', // prod
    stripePublicKey:
      'pk_live_51ILI5WLKBrDEnd60lO1ndmxAvxkRhCHmRB0DrifYGPE8Mmcq72UI6CJrvud2GZg8bpLSvbiJhBO5syQC1gnZL50Q00bz6VwTkz',
    visitorSiteDomain: 'https://visitor.goodmenu.io',
  },
}

export const config = configs[APP_ENV]
