/**
 * Tanstack Queryで全くキャッシュを使わせたくない場合の設定
 *
 * 更新系画面の元データなどが再取得されると都合が悪いことが多いので、
 * そのような場面で使用することを想定したもの。
 */
export const noCacheConfig = {
  staleTime: 0,
  gcTime: 0,
} as const
