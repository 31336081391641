import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  padding?: number
}
export const PaidPlanOnly: FC<Props> = ({ padding = 12 }) => {
  const { t } = useTranslation()

  return (
    <div
      css={css`
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        bottom: ${padding}px;
        display: flex;
        justify-content: center;
        left: ${padding}px;
        position: absolute;
        right: ${padding}px;
        top: ${padding}px;
      `}
    >
      <Typography
        css={css`
          color: white;
        `}
      >
        {t('Only available to paid plan users')}
      </Typography>
    </div>
  )
}
