import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import { useStripe } from '@stripe/react-stripe-js'
import { useMutation } from '@tanstack/react-query'

export function useMutationCreateCheckoutSession() {
  const stripe = useStripe()

  return useMutation({
    mutationFn: async (priceId) => {
      if (!stripe) {
        // Stripeは読み込まれていない可能性がある
        // https://docs.stripe.com/stripe-js/react#usestripe-%E3%83%95%E3%83%83%E3%82%AF
        console.error('Stripeがまだ読み込まれていません')
        return
      }

      const url = `${config.apiDomain}/v1/stripe/create_checkout_session/`
      const { session_id: sessionId } = await myKy
        .post(url, { json: { priceId } })
        .json<{ session_id: string }>() // 楽観的

      await stripe.redirectToCheckout({ sessionId })
    },
  })
}
