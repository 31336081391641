import type { PlanName } from '@/features/api/types'
import type { User as FirebaseUser } from 'firebase/auth'
import { atom, useAtomValue, useSetAtom } from 'jotai'

export type UserInfo = {
  id: string // from database
  uid: string // from firebase
  avatar: string // from firebase
  email: string // from firebase
  firebaseUser: FirebaseUser // from firebase
  name: string // from firebase
  plan: PlanName // from local user
  registration_date: string // from local user
}

/**
 * FirebaseではなくPostgresのデータベースに入っているユーザー情報
 * TODO: この型はGraphQL導入の暁には消したい
 */
export type ExtraUserInfo = {
  id: string
  plan: PlanName
  created_at: string
}

type AuthorizedAuthStatus = {
  type: 'authorized'
  userInfo: UserInfo
}

type UnauthorizedAuthStatus = {
  type: 'unauthorized'
}

type CheckingAuthStatus = {
  type: 'checking'
}

type AuthStatus =
  | AuthorizedAuthStatus
  | UnauthorizedAuthStatus
  | CheckingAuthStatus

const authStatusAtom = atom<AuthStatus>({ type: 'checking' })

export const useAuthStatus = () => {
  return useAtomValue(authStatusAtom)
}

export const useSetAuthStatus = () => {
  return useSetAtom(authStatusAtom)
}

export const useUserInfo = (): UserInfo => {
  const authStatus = useAtomValue(authStatusAtom)
  if (authStatus.type !== 'authorized' || !authStatus.userInfo) {
    throw new Error('ユーザー情報が存在しません。AuthGuardの下にいますか？')
  }
  return authStatus.userInfo
}
