import { FieldTitle } from '@/components/FieldTitle'
import { FormikErrors } from '@/components/FormikErrors'
import {
  type Genre,
  type MaybeNew,
  genreLayoutTypes,
} from '@/features/api/types'
import { useMutationDeleteGenre } from '@/features/genreDetail/api/useMutationDeleteGenre'
import { useMutationUpsertGenre } from '@/features/genreDetail/api/useMutationUpsertGenre'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { css } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Switch,
  TextField,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import invariant from 'tiny-invariant'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'
import { useConfirmation } from '../confirmation/ConfirmationContext'

interface Props {
  existingGenre?: Genre
  isNew: boolean
}

export const GenreDetailViewForm: FC<Props> = ({
  existingGenre,
  isNew,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { isPending: isUpdating, mutate: upsertGenre } =
    useMutationUpsertGenre()

  const { isPending: isDeleting, mutate: mutateDelete } =
    useMutationDeleteGenre()

  const deleteGenre = async () => {
    // もうちょっときれいな方法でやりたい
    invariant(existingGenre, '削除対象のジャンルIDが取得できませんでした')

    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_genres:deletion confirmation', {
        name: existingGenre.name,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    mutateDelete(existingGenre.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.genresAll() })
        enqueueSnackbar(t('Deleted'), { variant: 'success' })
        navigate('/app/management/genres/', { replace: true })
      },
    })
  }

  let initialValues: MaybeNew<Genre>
  if (isNew) {
    initialValues = {
      description: '',
      // id
      is_hidden: false,
      layout_type: 'default',
      name: '',
      order_num: 10000,
    }
  } else {
    initialValues = {
      // @ts-expect-error TS18048
      description: existingGenre.description,
      // @ts-expect-error TS18048
      id: existingGenre.id,
      // @ts-expect-error TS18048
      is_hidden: existingGenre.is_hidden,
      // @ts-expect-error TS18048
      layout_type: existingGenre.layout_type,
      // @ts-expect-error TS18048
      name: existingGenre.name,
      // @ts-expect-error TS18048
      order_num: existingGenre.order_num,
    }
  }

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<MaybeNew<Genre>>({
      enableReinitialize: true,
      initialValues,
      onSubmit: (formValues) => {
        upsertGenre(formValues, {
          onSuccess: async (savedGenre) => {
            const message = isNew ? t('Added') : t('Updated')
            enqueueSnackbar(message, { variant: 'success' })
            await queryClient.invalidateQueries({
              queryKey: queryKeys.genresAll(),
            })
            if (isNew) {
              navigate(`/app/management/genres/${savedGenre.id}/`, {
                replace: true,
              })
            }
          },
        })
      },
      validationSchema: Yup.object().shape({
        description: Yup.string().max(200),
        name: Yup.string().max(100).required(),
        order_num: Yup.number().required(),
      }),
    })

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <Card>
        <CardContent>
          <Grid container={true} spacing={3}>
            <Grid item={true} md={6} xs={12}>
              <FieldTitle title={`${t('ui_genres:Genre name')} *`} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth={true}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <FieldTitle
                title={`${t('Sort number')} *`}
                tooltipText={t('ui_products:Hint of sort number')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.order_num && errors.order_num)}
                  fullWidth={true}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  name="order_num"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.order_num}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <FieldTitle
                title={t('Additional description')}
                tooltipText={t('ui_genres:Hint for Additional description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <FieldTitle title={t('ui_genres:Layout type')} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.layout_type && errors.layout_type)}
                  fullWidth={true}
                  name="layout_type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select={true}
                  SelectProps={{ native: true }}
                  value={values.layout_type}
                  variant="outlined"
                >
                  {genreLayoutTypes.map((genreLayoutType) => {
                    const label = t(`ui_genres:layout_type_${genreLayoutType}`)
                    return (
                      <option key={genreLayoutType} value={genreLayoutType}>
                        {label}
                      </option>
                    )
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid
              css={css`
                position: relative;
              `}
              item={true}
              md={6}
              xs={12}
            >
              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.is_hidden}
                  color="secondary"
                  edge="start"
                  name="is_hidden"
                  onChange={handleChange}
                  value={values.is_hidden}
                />
                <FieldTitle
                  title={t('Hidden')}
                  tooltipText={t('ui_genres:Hint for hiding')}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider
            css={css`
              margin-top: 1rem;
            `}
          />
          <FormikErrors touched={touched} errors={errors} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isUpdating}
            >
              {isNew ? t('Add') : t('Update')}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {!isNew && (
        <Box mt={2}>
          <Button
            onClick={deleteGenre}
            disabled={isDeleting}
            aria-label="delete"
            size="small"
            tabIndex={-1}
          >
            {t('Delete')}
          </Button>
        </Box>
      )}
    </form>
  )
}
