import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { PlanName } from '@/features/api/types'

type Response = {
  id: string
  plan: PlanName
  created_at: string
}

export const apiRequestGetOwnerMy = () => {
  const url = `${config.apiDomain}/v1/owners/my/`
  return myKy.get(url).json<Response>()
}
