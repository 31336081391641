import { useQueryAllGenres } from '@/features/api/commonQueries/useQueryAllGenres'
import type { Menuitem } from '@/features/api/types'
import Page from '@/features/layout/Page'
import { useQueryMenuitem } from '@/features/menuitemDetail/api/useQueryMenuitem'
import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { MenuitemDetailViewForm } from './MenuitemDetailViewForm'
import { MenuitemDetailViewHeader } from './MenuitemDetailViewHeader'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
} satisfies SxPropStyles

const MenuitemDetailView: FC = () => {
  const { t } = useTranslation()

  const match = useMatch('/app/management/menuitems/:menuitemId')
  invariant(match?.params.menuitemId, 'menuitemIdが見つかりません')
  const { menuitemId } = match.params

  const isNew = menuitemId === 'new'

  const { data: menuitem } = useQueryMenuitem(menuitemId, !isNew)

  const genresQuery = useQueryAllGenres()
  const { results: genres } = genresQuery.data

  let menuitemName: string
  let existingMenuitem: Menuitem | undefined

  if (isNew) {
    menuitemName = t('ui_products:New product')
  } else {
    invariant(menuitem)
    menuitemName = menuitem.name
    existingMenuitem = menuitem
  }

  return (
    <Page sx={styles.root} title={t('Product')}>
      <Container maxWidth={false}>
        <MenuitemDetailViewHeader isNew={isNew} menuitemName={menuitemName} />
      </Container>
      <Box mt={3}>
        <Container maxWidth={false}>
          <MenuitemDetailViewForm
            isNew={isNew}
            existingMenuitem={existingMenuitem}
            genres={genres}
          />
        </Container>
      </Box>
    </Page>
  )
}

export default MenuitemDetailView
