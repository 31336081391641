import { config } from '@/config'
import { myKy } from '@/features/api/myKy'
import type { Menu, PaginatedResponse } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { isNullish } from '@/utils/isNullish'
import { useSuspenseQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

export const useQueryMenu = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.menu(),
    queryFn: async () => {
      const url = `${config.apiDomain}/v1/menus/`
      const result = await myKy.get(url).json<PaginatedResponse<Menu>>()
      const menus = result.results
      invariant(menus)

      // 1ユーザー1メニューを必ず持っている前提
      const menu = menus.find((thisMenu) => isNullish(thisMenu.backup_id))
      invariant(menu)
      return menu
    },
    ...noCacheConfig,
  })
}
